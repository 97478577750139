<template>
  <div class="modal" tabindex="-1" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
    <div :class="['modal-dialog', 'modal-dialog-scrollable', modal_size]" :style="style">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <slot name="header"></slot>
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hideModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button> -->
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    style: {
      type: Object
    },
    modal_size: {
      type: String,
      default: 'modal-xl'
    }
  },

  name: 'modal',
  methods:{
    hideModal(){
      this.$emit('hide-modal')
    }
  },
  // mounted(){
  //   console.log(this.modal_size)
  // }
}
</script>

<style></style>