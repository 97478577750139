<template>
  <div class="position-relative">
    <div class="row row-cols-1 row-cols-md-2 vh-100 m-0 z-3">
      <div class="col col-md-6 p-0">
        <div class="d-flex h-100">
          <div class="mx-auto mt-auto m-md-auto w-50 px-4 mb-5">
            <img src="@/assets/images/logos/lazismu-with-text.png" class="img-fluid" alt="Logo Lazismu">
            <h6 class="text-right fs-md-2">Kabupaten Blitar</h6>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="d-flex h-100">
          <div class="mx-auto mb-auto m-md-auto w-75 w-md-50 px-4">
            <form @submit.prevent="go_login">
              <div class="mb-3">
                <label for="username" class="form-label">Username</label>
                <input type="text" v-model="form.username" class="form-control" id="username" placeholder="Username">
              </div>
              <div class="mb-1">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" v-model="form.password"
                  placeholder="Password">
              </div>
              <router-link to="/forget">Lupa password ?</router-link>
              <button type="submit" class="btn btn-primary w-100 mt-4">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/images/bg-footer.jpg" class="position-absolute end-0 top-0 img-fluid z-[-99]"
      style="rotate: 180deg;" alt="">
    <img src="@/assets/images/bg-footer.jpg" class="position-absolute start-0 bottom-0 img-fluid z-[-99]" alt="">
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/Modal.vue";
export default ({
  name: 'login-form',
  components: {
    Modal
  },
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      modal: false
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    async go_login() {
      // this.modal = true
      let params = {
        username: this.form.username,
        password: this.form.password,
        usergroup: "1"

      }     

      await this.login(params)
        .then((a) => {
          console.log("login", a);
          if (a.code == 200) {
            this.modal = false
            this.$router.push({ 'name': 'dashboard' })
          } else {
            alert(a.message)
            this.modal = false
          }
        })
        .catch((e) => {
          console.log("form login: " + e);
        })
    }

  },

  beforeMount() {
    $('body').removeClass('sidebar-mini').addClass('login-page')
  },

  beforeUnmount() {
    $('body').removeClass('login-page')
  }

})
</script>

<style scoped>
@media (min-width: 768px) {
  .fs-md-2 {
    font-size: 2rem !important;
  }

  .w-md-50 {
    width: 50% !important;
  }
}
</style>