<template>
  <content-header title="Donasi Cash"></content-header>
  <content>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h3>Donasi Cash Pending</h3>
          </div>
          <div class="card-body">
            <h6 class="mb-3">Menampilkan data donasi cash/uang yang masih belum di validasi oleh Admin</h6>
            <EasyDataTable :headers="donasi.pending.headers" :items="donasi.pending.items">
              <template #item-donatur="item">
                {{ item.nama }}
              </template>

              <template #item-nominal="item">
                {{ item.donasi }}
              </template>

              <template #item-dokumentasi="item">
                <span v-if="item.photo_file != null && item.photo_file != ''">
                  <span class="badge bg-success pointer" @click="openDokumentasi(item)">Lihat</span>
                </span>
                <span v-else>Tidak Ada</span>
              </template>

              <template #item-amil="item">
                {{ item.amil }}
              </template>

              <template #item-status="item">
                {{ item.statusDonasi }}
              </template>

              <template #item-option="item">
                <span v-if="item.statusDonasi == 'Pending'">
                  <button class="btn btn-xs bg-olive mr-1 mb-1" @click="confirmTerimaDonasi(item)">Update</button>
                  <!-- <button class="btn btn-xs bg-orange mb-1" @click="confirmTolakDonasi(item)">Tolak</button> -->
                </span>
              </template>



            </EasyDataTable>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h3>Riwayat Donasi (Store procedure belum ada)</h3>
          </div>
          <div class="card-body">
            <h6 class="mb-3">Menampilkan data donasi yang sudah di validasi oleh admin</h6>
            <EasyDataTable :headers="donasi.donasi.headers" :items="donasi.donasi.items">
              <template #item-donatur="item">
                {{ item.nama }}
              </template>

              <template #item-nominal="item">
                {{ item.donasi }}
              </template>

              <template #item-dokumentasi="item">
                <span v-if="item.photo_file != null && item.photo_file != ''">
                  file: {{ item.photo_file }} <span class="badge bg-success pointer">open</span>
                </span>
              </template>

              <template #item-amil="item">
                {{ item.amil }}
              </template>

              <template #item-status="item">
                {{ item.statusDonasi }}
              </template>

              <template #item-option="item">
                <span v-if="item.statusDonasi == 'Pending'">
                  <button class="btn btn-xs bg-olive mr-1 mb-1" @click="confirmTerimaDonasi(item)">Terima</button>
                  <!-- <button class="btn btn-xs bg-orange mb-1" @click="confirmTolakDonasi(item)">Tolak</button> -->
                </span>
              </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </content>

  <modal v-if="utils.modal.form_tambah_donasi.show" @hide-modal="hideModal()" :modal_size="'modal-lg'">
    <template v-slot:header >Formulir Update Donasi Cash</template>
    <template v-slot:content>
      <FormTerimaDonasi :data="utils.modal.form_tambah_donasi" @close-modal="hideModalReload()" ref="formTerimaDonasiRef"></FormTerimaDonasi>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-danger" @click="hideModal()">Keluar</button>
      <button type="button" class="btn bg-olive" @click="updateTerimaDonasi()">Simpan</button>
    </template>

  </modal>

  <modal v-if="utils.modal.dokumentasi.show" @hide-modal="hideModal()">
    <template v-slot:header></template>
    <template v-slot:content>
      <img :src="dokumentasi" alt="dokumentasi">
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-danger btn-sm" @click="hideModal()">Keluar</button>
    </template>
  </modal>


</template>

<script setup>

import { onMounted, computed, ref, reactive } from 'vue';
import axios from "axios";
import { useStore } from "vuex";
import Modal from "@/components/Modal.vue";
import FormTerimaDonasi from '@/views/donasi/FormTerimaDonasi.vue';
import Swal from 'sweetalert2';

const store = useStore();

const user = computed(() => {
  return store.state.auth.user
})

const formTerimaDonasiRef = ref()

const dokumentasi = ref("")

const utils = reactive({
  url_donasi_cash_pending: "api/donasi",
  modal: {
    form_tambah_donasi:{
      show: false,
      method: "",
      data: {}
    },
    dokumentasi:{
      show: false,
      data: {}
    }
  }
})

const reward = ref("")

const donasi = reactive({
  pending: {
    headers: [
      { text: 'Tanggal', value: 'tanggal' },
      { text: 'Donatur', value: 'donatur' },
      { text: 'Jenis Donasi', value: 'jenis_donasi' },
      { text: 'Nominal', value: 'nominal' },
      { text: 'Akun', value: 'akun' },
      { text: 'Reward', value: 'reward' },
      { text: 'Dokumentasi', value: 'dokumentasi' },
      { text: 'Amil', value: 'amil' },
      { text: 'Status', value: 'status' },
      { text: 'aksi', value: 'option' },
    ],
    items: [],
  },
  donasi: {
    headers: [
      { text: 'Tanggal', value: 'tanggal' },
      { text: 'Donatur', value: 'donatur' },
      { text: 'Jenis Donasi', value: 'jenis_donasi' },
      { text: 'Nominal', value: 'nominal' },
      { text: 'Akun', value: 'akun' },
      { text: 'Reward', value: 'reward' },
      { text: 'Dokumentasi', value: 'dokumentasi' },
      { text: 'Amil', value: 'amil' },
      { text: 'Status', value: 'status' },
      // { text: 'aksi', value: 'option' },
    ],
    items: [],
  }
})

function init() {
  fetchDonasiCashPending()
  fetchDonasiCash()
}

const hideModal = () => {
  utils.modal.form_tambah_donasi.show = false
  utils.modal.dokumentasi.show = false
}

const hideModalReload = () => {
  hideModal()
  init()
  
}

const fetchDonasiCashPending = async () => {
  let params = {
    username: user.value.username
  }

  await axios.get(`${utils.url_donasi_cash_pending}/donasi-uang-pending`, { params })
    .then((a) => {
      
      if (a.data.code == 200) {
        donasi.pending.items = a.data.response
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {

    })
}

const fetchDonasiCash = async () => {
  // let params = {
  //   username: user.value.username
  // }
}

const confirmTerimaDonasi_sweetalert = async (item) => {
  reward.value = ""
  const { value: isSetReward } = await Swal.fire({
    title: `Apakah Anda yakin? ${reward.value}`,
    html: `
    <div>Aksi ini akan merubah status donasi pending menjadi diterima</div> 
    <div class="form-group">
      <label for="reward">Tambahkan Reward kepada ${item.amil}</label>
      <input type="number" id="input_reward" class="form-control" v-model="reward" placeholder="reward">  
    </div>
    ` ,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya, Terima!',
    preConfirm: () => {
      const rewardinput = document.getElementById('input_reward').value
      if (!rewardinput) {
        Swal.showValidationMessage(
          'Anda harus memberikan nilai pada colom reward'
        )
      }

      return rewardinput
    }


  })
  if (isSetReward) {
    reward.value = isSetReward
    let params = {
      id: String(item.id),
      user: String(user.value.username),
      status: "1",
      reward: String(reward.value)

    }
    console.log(params);

    axios.post(`${utils.url_donasi_cash_pending}/approve-donasi-uang`, params)
      .then((a) => {
        console.log(a.data);
        if (a.data.code == 200) {
          Swal.fire('Terima Kasih', 'Donasi Berhasil', 'success')
          fetchDonasiCashPending()
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        
      })
    
  }

}

const confirmTerimaDonasi = async (item) => {
  // console.log(item);
  utils.modal.form_tambah_donasi.show = true
  utils.modal.form_tambah_donasi.data = item
  utils.modal.form_tambah_donasi.method = "update"
  
}

const updateTerimaDonasi = async () => {
  formTerimaDonasiRef.value.updateData()
}



const openDokumentasi = (item) => {
  console.log(item);
  
  dokumentasi.value = `http://192.168.100.112:8001/uploads/${item.photo_file}`
  utils.modal.dokumentasi.show = true
}

onMounted(() => {
  init()
})

</script>

<style></style>