<template>
  <div>

    <div class="form-group row">
      <label for="tanggal" class="col-sm-2 col-form-label">Tanggal</label>
      <input type="text" class="form-control col-sm-10" id="tanggal" v-model="form_data.tanggal" disabled>
    </div>

    <div class="form-group row">
      <label for="donatur" class="col-sm-2 col-form-label">Donatur</label>
      <input type="text" class="form-control col-sm-10" id="donatur" v-model="form_data.donatur" disabled>
    </div>

    <div class="form-group row">
      <label for="donasi" class="col-sm-2 col-form-label">Nominal Donasi</label>
      <input type="text" class="form-control col-sm-10" id="donasi" v-model="form_data.nominal" disabled>
    </div>

    <div class="form-group row">
      <label for="amil" class="col-sm-2 col-form-label">Amil</label>
      <input type="text" class="form-control col-sm-10" id="amil" v-model="form_data.amil" disabled>
    </div>

    

    <div class="form-group">
      <label for="status">Status</label>
      <select class="form-control" id="status" v-model="form_data.status">
        <option>--</option>
        <option v-for="item in utils.cbo_data.status_donasi" :key="item.id" :value="item.id">{{ item.statusDonasi }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="coa">COA</label>
      <select class="form-control" id="coa" v-model="form_data.coa">
        <option>--</option>
        <option v-for="item in utils.cbo_data.kode_akuntansi" :key="item.kode" :value="item.kode">{{ item.nama }} - {{ item.kode }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="reward">Reward</label>
      <div class="input-group mb-3">
        <input type="number" class="form-control" id="reward" v-model="form_data.reward">
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">%</span>
        </div>
      </div>
    </div>


  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import Swal from 'sweetalert2';

const store = useStore()

const user = computed(() => {
  return store.getters['auth/user']
})

const props = defineProps({
  data: {
    type: Object
  }
})

const emit = defineEmits(['closeModal'])

const form_data = reactive({
  id: '',
  user: '',
  status: '',
  coa: '',
  reward: '',
  tanggal: '',
  amil: '',
  donatur: '',
  nominal: ''
})

const utils = reactive({
  baseUrl: 'api/donasi',
  urlAkuntansi: 'api/akuntansi',
  cbo_data:{
    status_donasi: [],
    kode_akuntansi: []
  }
})

const init = () => {
  console.log(props.data);
  cboStatusDonasi()
  cboKodeAkuntansi()
  setupForm()
  
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 1500,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const cboStatusDonasi = async () => {
  try {
    const resp = await axios.get(`${utils.baseUrl}/cbo-status-donasi`)
    if (resp.data.code == 200) {
      
      utils.cbo_data.status_donasi = resp.data.response
      
    }
  } catch (error) {
    console.log(error);
    
  }
}

const cboKodeAkuntansi = async () => {
  try {
    const resp = await axios.get(`${utils.urlAkuntansi}/cbo-kode-akuntansi`)
    // console.log(resp.data);
    
    if (resp.data.code == 200) {
      utils.cbo_data.kode_akuntansi = resp.data.response
    }
  } catch (error) {
    console.log(error);
    
  }
}

const setupForm = () => {  
  form_data.id = props.data.data.id
  form_data.user = user.value.username
  form_data.status = props.data.data.status_id
  form_data.coa = props.data.data.coa
  form_data.reward = props.data.data.reward
  form_data.tanggal = props.data.data.tanggal
  form_data.amil = props.data.data.amil
  form_data.donatur = props.data.data.nama
  form_data.nominal = props.data.data.donasi
  form_data.jenis_donasi = props.data.data.jenis_donasi_id
  // console.log(form_data.status);
  
}

const updateData = async () => {
  console.log("form data", form_data);
  if (form_data.status == "" || form_data.coa == "") {
    alert("Semua kolom wajib diisi")
    return 
  }

  let params = form_data

  try {
    const resp = await axios.post(`${utils.baseUrl}/approve-donasi-uang`, params)
    console.log(resp.data);
    
    if (resp.data.code == 200) {
      Toast.fire({
        icon: 'success',
        title: resp.data.response
      })

      emit('closeModal')
    }
  } catch (error) {
    console.log(error);
  }
}

defineExpose({
  updateData
})

onMounted(() => {
  init()
})

</script>

<style>

</style>