<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-8">
      <div class="form-group">
        <label for="namalengkap">Nama Lengkap</label>
        <input type="text" class="form-control" id="namalengkap" v-model="adduser.nama">
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" v-model="adduser.email">
      </div>

      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" v-model="adduser.password">
      </div>

      <div class="form-group">
        <label for="nik">NIK</label>
        <input type="text" class="form-control" id="nik" v-model="adduser.nik">
      </div>

      <div class="form-group">
        <label for="telpon">Telpon</label>
        <input type="text" class="form-control" id="telpon" v-model="adduser.telepon">
      </div>

      <div class="form-group">
        <label for="amalat">Alamat</label>
        <input type="text" class="form-control" id="amalat" v-model="adduser.alamat">
      </div>

      <!-- <div class="form-group">
        <label for="rt">RT</label>
        <input type="text" class="form-control" id="rt" v-model="adduser.rt">
      </div>

      <div class="form-group">
        <label for="rw">RW</label>
        <input type="text" class="form-control" id="rw" v-model="adduser.rw">
      </div> -->

      <div class="form-group">
        <label for="provinsi">Provinsi</label>
        <input type="search" autocomplete="off" class="form-control" id="provinsi" v-model="provinsi.search_provinsi"
          @input="provinsiData()">
        <ul class="list-group" v-if="provinsi.items.length > 0">
          <li v-for="item in provinsi.items" :key="item.id" class="list-group-item" @click="selectProvinsi(item)">
            {{ item.provinsi }}</li>
        </ul>
      </div>

      <div class="form-group">
        <label for="kabupaten">Kabupaten / Kota</label>
        <input type="search" autocomplete="off" class="form-control" id="kabupaten" v-model="kabupaten.search_kabupaten"
          @input="kabupatenData()" :disabled="kabupaten.isDisabled">
        <ul class="list-group" v-if="kabupaten.items.length > 0">
          <li v-for="item in kabupaten.items" :key="item.id" class="list-group-item" @click="selectKabupaten(item)">
            {{ item.kabupaten }}</li>
        </ul>
      </div>

      <div class="form-group">
        <label for="kecamatan">Kecamatan</label>
        <input type="search" autocomplete="off" class="form-control" id="kecamatan" v-model="kecamatan.search_kecamatan"
          @input="kecamatanData()" :disabled="kecamatan.isDisabled">
        <ul class="list-group" v-if="kecamatan.items.length > 0">
          <li v-for="item in kecamatan.items" :key="item.id" class="list-group-item" @click="selectKecamatan(item)">
            {{ item.kecamatan }}</li>
        </ul>
      </div>

      <div class="form-group">
        <label for="kelurahan">Kelurahan</label>
        <input type="search" autocomplete="off" class="form-control" id="kelurahan" v-model="kelurahan.search_kelurahan"
          @input="kelurahanData()" :disabled="kelurahan.isDisabled">
        <ul class="list-group" v-if="kelurahan.items.length > 0">
          <li v-for="item in kelurahan.items" :key="item.id" class="list-group-item" @click="selectKelurahan(item)">
            {{ item.kelurahan }}</li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="approve" class="col-sm-2 col-form-label">Approve</label>
        <div class="col-sm-10">
          <!-- <input type="email" class="form-control" id="approve" placeholder="Email"> -->
           <select name="approve" id="approve" v-model="donatur.selected" class="form-control">
             <option value="0">--</option>
             <option v-for="item in donatur.items" :key="item.id" :value="item.id">{{ item.status }}</option>
           </select>
        </div>
      </div>

      <div>
        <label for="image">KTP</label> <br>
        <div v-if="isKtpExist">
          <img :src="imageFile" alt="" height="200px">
          <br>
          <button class="btn btn-sm btn-primary" @click="updateKtp()">Update KTP</button>
        </div>
        <div v-else>
          <input type="file" class="form-control" id="image" @change="onFileChange">
        </div>
      </div>

      <!-- <div class="row"> -->
      <button class="btn btn-primary float-right" @click="updateDonatur()">Simpan</button>
      <!-- </div> -->

    </div>
  </div>

</template>

<script>

import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["modal_data"],
  name: "updatedonatur",
  data() {
    return {
      url: '/api',
      adduser: {
        nick: '',
        id: '',
        nik: '',
        nama: '',
        alamat: '',
        rt: '',
        rw: '',
        provinsi: '',
        provinsi_id: '',
        kabupaten: '',
        kabupaten_id: '',
        kecamatan: '',
        kecamatan_id: '',
        kelurahan: '',
        kelurahan_id: '',
        telepon: '',
        jenisDonasi: '',
        username: '',
        password: '',
        email: '',
        amil: '',
        ktp_file: '',
      },
      provinsi: {
        items: [],
        search_provinsi: "",
        selected_id: "",
        selected_provinsi: ""
      },
      kabupaten: {
        isDisabled: true,
        items: [],
        search_kabupaten: "",
        selected_id: "",
        selected_kabupaten: ""
      },
      kecamatan: {
        isDisabled: true,
        items: [],
        search_kecamatan: "",
        selected_id: "",
        selected_kecamatan: ""
      },
      kelurahan: {
        isDisabled: true,
        items: [],
        search_kelurahan: "",
        selected_id: "",
        selected_kelurahan: ""
      },
      update: {
        status: false
      },
      donatur:{
        items: [],
        selected: ''
      },
      file: null,
      isKtpExist: false,
      imageFile: ""
    }
  },

  computed: {
    ...mapGetters({
      "user": "auth/user"
    })
  },

  methods: {
    init() {
      this.cbo_status_donatur()
    },

    addDonatur() {

    },

    resetProvinsi() {
      this.kabupaten.selected_kabupaten = ""
      this.kabupaten.search_kabupaten = ""
      this.kabupaten.selected_id = ""
      // this.kabupaten.isDisabled = true

      this.kecamatan.selected_kecamatan = ""
      this.kecamatan.search_kecamatan = ""
      this.kecamatan.selected_id = ""
      // this.kecamatan.isDisabled = true

      this.kelurahan.selected_kelurahan = ""
      this.kelurahan.search_kelurahan = ""
      this.kelurahan.selected_id = ""
    },

    resetKabupaten() {
      this.kecamatan.selected_kecamatan = ""
      this.kecamatan.search_kecamatan = ""
      this.kecamatan.selected_id = ""

      this.kelurahan.selected_kelurahan = ""
      this.kelurahan.search_kelurahan = ""
      this.kelurahan.selected_id = ""
    },

    resetKecamatan() {

      this.kelurahan.selected_kelurahan = ""
      this.kelurahan.search_kelurahan = ""
      this.kelurahan.selected_id = ""
    },

    provinsiData() {
      let search = this.provinsi.search_provinsi
      if (search.length == 0) {
        this.resetProvinsi()
      }

      if (search.length > 3) {
        axios.get(`${this.url}/provinsi`)
          .then((a) => {
            if (a.data.code == 200) {
              let data = a.data.response
              // console.log(data);
              const temp_provinsi = data.filter(item => item.provinsi.toLowerCase().includes(search.toLowerCase()))
              // console.log(temp_provinsi);
              this.provinsi.items = temp_provinsi

            }

          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.provinsi.items = []
        this.provinsi.selected_id = ""
        this.provinsi.selected_provinsi = ""

      }


    },

    selectProvinsi(item) {
      // this.provinsi.selected = item
      if (this.provinsi.selected_provinsi != "" || this.provinsi.selected_provinsi != null && this.provinsi.selected_provinsi != item.provinsi) {
        console.log("harusnya masuk sini karena ganti provinsi");

        this.kabupaten.selected_kabupaten = ""
        this.kabupaten.search_kabupaten = ""
        this.kabupaten.selected_id = ""
        // this.kabupaten.isDisabled = true

        this.kecamatan.selected_kecamatan = ""
        this.kecamatan.search_kecamatan = ""
        this.kecamatan.selected_id = ""
        // this.kecamatan.isDisabled = true

        this.kelurahan.selected_kelurahan = ""
        this.kelurahan.search_kelurahan = ""
        this.kelurahan.selected_id = ""
        // this.kelurahan.isDisabled = true

        this.provinsi.items = []
        this.provinsi.selected_id = item.id
        this.provinsi.search_provinsi = item.provinsi
        this.provinsi.selected_provinsi = item.provinsi

        if (this.provinsi.selected_id != "") {
          this.kabupatenData()
        }
      } else {
        console.log("tidak ganti provinsi");

        this.provinsi.items = []
        this.provinsi.selected_id = item.id
        this.provinsi.search_provinsi = item.provinsi
        this.provinsi.selected_provinsi = item.provinsi
        if (this.provinsi.selected_id != "") {
          this.kabupatenData()
        }
      }
    },

    kabupatenData() {
      this.kabupaten.isDisabled = false
      if (this.kabupaten.search_kabupaten.length == 0) {
        this.resetKabupaten()

      }

      if (this.kabupaten.search_kabupaten.length > 3) {
        axios.get(`${this.url}/kabupaten`, {
          params: {
            provinsi: this.provinsi.selected_id
          }
        }).then((a) => {
          if (a.data.code == 200) {
            let list_kabupaten = a.data.response
            let temp_kabupaten = list_kabupaten.filter(item => item.kabupaten.toLowerCase().includes(this.kabupaten.search_kabupaten.toLowerCase()))
            this.kabupaten.items = temp_kabupaten
          }
        })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.kabupaten.items = []
        this.kabupaten.selected_id = ""
        // this.kabupaten.search_kabupaten = ""
      }
    },

    selectKabupaten(item) {
      this.kabupaten.items = []
      this.kabupaten.selected_id = item.id
      this.kabupaten.search_kabupaten = item.kabupaten
      this.kabupaten.selected_kabupaten = item.kabupaten
      if (this.kabupaten.selected_id != "") {
        this.kecamatanData()
      }
    },

    kecamatanData() {
      this.kecamatan.isDisabled = false
      if (this.kecamatan.search_kecamatan.length == 0) {
        this.resetKecamatan()

      }
      if (this.kecamatan.search_kecamatan.length > 3) {
        axios.get(`${this.url}/kecamatan`, {
          params: {
            kabupaten: this.kabupaten.selected_id
          }
        }).then((a) => {
          if (a.data.code == 200) {
            let list_kecamatan = a.data.response
            let temp_kecamatan = list_kecamatan.filter(item => item.kecamatan.toLowerCase().includes(this.kecamatan.search_kecamatan.toLowerCase()))
            this.kecamatan.items = temp_kecamatan
          }
        })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.kecamatan.items = []
        this.kecamatan.selected_id = ""
        // this.kecamatan.search_kecamatan = ""
      }
    },

    selectKecamatan(item) {
      this.kecamatan.items = []
      this.kecamatan.selected_id = item.id
      this.kecamatan.search_kecamatan = item.kecamatan
      this.kecamatan.selected_kecamatan = item.kecamatan
      if (this.kecamatan.selected_id != "") {
        this.kelurahanData()
      }
    },

    kelurahanData() {
      this.kelurahan.isDisabled = false

      if (this.kelurahan.search_kelurahan.length > 3) {
        axios.get(`${this.url}/kelurahan`, {
          params: {
            kecamatan: this.kecamatan.selected_id
          }
        }).then((a) => {
          if (a.data.code == 200) {
            let list_kelurahan = a.data.response
            let temp_kelurahan = list_kelurahan.filter(item => item.kelurahan.toLowerCase().includes(this.kelurahan.search_kelurahan.toLowerCase()))
            this.kelurahan.items = temp_kelurahan
          }
        })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.kelurahan.items = []
        this.kelurahan.selected_id = ""
        // this.kelurahan.search_kelurahan = ""
      }
    },

    selectKelurahan(item) {
      this.kelurahan.items = []
      this.kelurahan.selected_id = item.id
      this.kelurahan.search_kelurahan = item.kelurahan
      this.kelurahan.selected_kelurahan = item.kelurahan
      this.adduser.kelurahan_id = item.id


    },
    
    cbo_status_donatur() {
      axios.get(`${this.url}/donatur/cbo-status-donatur`)
      .then((a) => {
        // console.log(a.data);
        
        if (a.data.code == 200) {
          this.donatur.items = a.data.response
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        console.log();
      })
    },

    updateKtp() {
      this.isKtpExist = false
    },
    
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.file = files[0]
    },

    async updateDonatur() {
      // console.log(this.donatur);

      const formData = new FormData()

      if (this.file != null) {
        formData.append('file', this.file)
      }
      formData.append('nick', this.user.username)
      formData.append('id', this.adduser.id)
      formData.append('nik', this.adduser.nik)
      formData.append('nama', this.adduser.nama)
      formData.append('alamat', this.adduser.alamat)
      formData.append('kelurahan', this.adduser.kelurahan_id)
      formData.append('telepon', this.adduser.telepon)
      formData.append("jenisDonasi", "")
      formData.append('email', this.adduser.email)
      formData.append('password', this.adduser.password)
      formData.append('amil', this.user.username)
      formData.append('ktp_file', this.adduser.ktp_file)
      formData.append('status', this.donatur.selected == 0 ? "": String(this.donatur.selected))

      
      // let params = {
      //   nick: this.user.username,
      //   id: String(this.adduser.id),
      //   nik: this.adduser.nik,
      //   nama: this.adduser.nama,
      //   alamat: this.adduser.alamat,
      //   kelurahan: this.adduser.kelurahan_id,
      //   telepon: this.adduser.telepon,
      //   jenisDonasi: "",
      //   email: this.adduser.email,
      //   password: this.adduser.password,
      //   amil: this.user.username,
      //   status: this.donatur.selected == 0 ? "": String(this.donatur.selected),
      //   ktp_file: this.adduser.ktp_file
      // }

      // console.log(params);

      for( const [key, value] of formData.entries() ) {
        console.log(key, value);
      }
      // return 


      await axios.post(`${this.url}/donatur/admin-approve-donatur`, formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then((a) => {
          console.log(a.data);
          if (a.data.code == 200) {
            alert(a.data.response)
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.$emit('hide-modal')
        })
    },

    async userDetail() {
      let params = {
        nick: this.user.username,
        donatur_id: this.modal_data.data.id
      }
      // console.log("Params ", params);


      let data = await axios.get(`${this.url}/donatur/detail`, { params })
        .then((a) => {
          // console.log(a.data);
          return a.data.response
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {

        })

        return data
    },

    async setupUser() {
      // console.log("log setup user", this.modal_data.data.id);

      if (this.modal_data.method == "update") {
        // console.log(this.modal_data);
        
        let data = await this.userDetail()
        // let data = this.modal_data.data
        console.log("user detail", data);
        

        this.adduser.nick = this.user.username
        this.adduser.id = data.id
        this.adduser.nik = data.nik
        this.adduser.nama = data.nama
        this.adduser.alamat = data.alamat
        this.adduser.rt = data.rt
        this.adduser.rw = data.rw

        this.adduser.provinsi = data.provinsi
        this.adduser.provinsi_id = data.provinsi_id
        this.provinsi.search_provinsi = data.provinsi
        this.provinsi.selected_id = data.provinsi_id
        this.provinsi.selected_provinsi = data.provinsi

        this.adduser.kabupaten = data.kabupaten
        this.adduser.kabupaten_id = data.kabupaten_id
        this.kabupaten.search_kabupaten = data.kabupaten
        this.kabupaten.selected_id = data.kabupaten_id
        this.kabupaten.selected_kabupaten = data.kabupaten

        this.adduser.kecamatan = data.kecamatan
        this.adduser.kecamatan_id = data.kecamatan_id
        this.kecamatan.search_kecamatan = data.kecamatan
        this.kecamatan.selected_id = data.kecamatan_id
        this.kecamatan.selected_kecamatan = data.kecamatan

        this.adduser.kelurahan = data.kelurahan
        this.adduser.kelurahan_id = data.kelurahan_id
        this.kelurahan.search_kelurahan = data.kelurahan
        this.kelurahan.selected_id = data.kelurahan_id
        this.kelurahan.selected_kelurahan = data.kelurahan

        this.adduser.telepon = data.telepon
        this.adduser.username = data.username
        this.adduser.password = data.password
        this.adduser.email = data.email
        this.adduser.amil = this.user.username
        this.adduser.ktp_file = data.ktp_file
        this.donatur.selected = data.statusDonatur_id

        if (data.ktp_file != "") {
          this.isKtpExist = true
          this.imageFile = `http://192.168.100.112:8001/uploads/` + data.ktp_file
        }

        
      }
    }


  },

  mounted() {
    this.init()
    this.setupUser()
  }
}
</script>

<style></style>