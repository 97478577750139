<template>
  <content-header title="Donatur"></content-header>
  <content>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 ml-auto mb-2">
                <input type="search" placeholder="pencarian" autocomplete="off" class="form-control" id="search" v-model="donatur.search">
              </div>
            </div>
            <EasyDataTable show-index :items="donatur.items" :headers="donatur.headers">
              
              <template #item-status="item">

                  <span class="badge bg-olive" v-if="item.statusDonatur == 'Active'">{{item.statusDonatur}}</span>
                  <span class="badge bg-danger" v-else-if="item.statusDonatur == 'Non Active'">{{item.statusDonatur}}</span>

                  <span v-else class="badge bg-warning">{{ item.statusDonatur }}</span>

                  
                  </template>
              
              <template #item-option="item">
                <span class="badge badge-primary pointer" @click="openModalEditDonatur(item)">Update</span>
              </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </content>

  <modal v-if="modal.donatur" @hide-modal="hideModal()">
    <template v-slot:header >Update Data</template>
    <template v-slot:content>
      <update-donatur :modal_data="modal.data" @hide-modal="fetchDonatur()"></update-donatur>
    </template>

  </modal>
</template>



<script>

import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Modal from "@/components/Modal.vue";
import UpdateDonatur from "@/components/UpdateDonatur.vue";

export default{
  name: 'donatur-list',
  components:{
    Modal, UpdateDonatur
  },
  data() {
    return {
      url: '/api',
      donatur: {
        items: [],
        headers: [
          {
            text: 'ID',
            value: 'id',
          },
          {
            text: 'Nama',
            value: 'nama',
          },
          {
            text: 'Alamat',
            value: 'alamat_lengkap',
          },
          {
            text: 'Status',
            value: 'status',
          },
          {
            text: 'Option',
            value: 'option',
          }
        ],
      },

      modal:{
        data: {},
        donatur: false
      },

    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    init() {
      this.fetchDonatur()
    },

    hideModal() {
      this.modal.donatur = false
    },

    fetchDonatur() {
      this.hideModal()
      axios.get(`${this.url}/donatur/amil-list-donatur`, { params: { nick: this.user.username, usergroup: 1 } })
      .then((a)=>{
        console.log(a.data);
        if (a.data.code == 200) {
          this.donatur.items = a.data.response
        }
      })
      .catch((e)=>{
        console.log(e);
      })
      .finally(()=>{
      
      })
    },

    openModalEditDonatur(item){
      this.modal.data = {
        method: 'update',
        data: item
      }

      this.modal.donatur = true
    }
  },

  mounted() {
    this.init();
  }
}
</script>

<style>

</style>